body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
  position: relative;
}
//
.sms-logo{
  width: 100px;
  height: auto;
  display: block;
  float: right;
}
